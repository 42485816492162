@media (min-width: 768px) {
  #form {
    padding-left: 20%;
    padding-right: 20%;
    max-width: 80%; /* 화면의 80%만 사용 */
    margin: 0 auto;
  }
}

.textInput,
#btn {
  width: calc(100% - 2rem);
  max-width: 400px;
  margin: 0.5rem 0;
}
